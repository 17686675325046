<template>
  <div class="flex flex-col items-center">
    <div class="w-full xl:w-1/2">
      <div class="mb-2">
        <a-button @click="$router.back()">
          Regresar
        </a-button>
      </div>
      <form @submit.prevent="update(document).then(() => $router.back())" @keydown="removeError($event.target.id)" class="space-y-4">
        <h3 class="my-4 text-2xl">Actualizar documento</h3>
        <a-input
          label="Título"
          required
          class="mb-4"
          id="title"
          :error="error('title')"
          v-model="document.title" />

        <a-select
          label="Tipo"
          required
          class="mb-4"
          :error="error('type')"
          :source="[
            { label: 'Libro', value: 1 },
            { label: 'Papel', value: 2 },
            { label: 'Tesis', value: 3 },
            { label: 'Otro', value: 4 }
          ]"
          v-model="document.type"/>

        <a-select
          label="Período (opcional)"
          class="mb-4"
          @input="removeError('period_id')"
          :loading="loadings.periods"
          :source="periods.map(el => ({ label: `${el.year}-${el.number}`, value: el.id }))"
          :error="error('period_id')"
          v-model="document.period_id"/>

        <a-select
          label="Especialidad (opcional)"
          class="mb-4"
          v-if="document.period_id"
          @input="removeError('specialization_id')"
          :source="specializations.map(el => ({ label: el.title, value: el.id }))"
          :error="error('specialization_id')"
          v-model="document.specialization_id"/>

        <template v-if="document.specialization_id">
          <label for="semester_id" class="block my-2">Semestre (Opcional)</label>
          <select id="semester_id" class="mb-4 w-full mt-2 p-2 appearance-none border bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary" @input="removeError('semester_id')" v-model="document.semester_id">
            <option :value="semester.id" v-for="semester in semesters" :key="semester.id">
              Semestre {{ semester.number }}
            </option>
          </select>
        </template>

        <a-select
          label="Materia (opcional)"
          class="mb-4"
          @input="removeError('course_id')"
          v-if="document.semester_id"
          :source="courses.map(el => ({ label: el.title, value: el.id }))"
          :error="error('course_id')"
          v-model="document.course_id"/>
        <div class="flex space-x-2">
          <a-button mode="primary" type="submit" :loading="loading" :disabled="loadings.periods">
            Actualizar cambios
          </a-button>

          <a-button outlined :to="{ name: 'documents' }">
            Cancelar
          </a-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data: () => ({
    document: {
      title: ''
    },
    isUpdating: false,
    periods: [],
    loadings: {
      periods: false
    }
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error'
    }),
    ...mapState({
      loading: state => state.documents.loading,
    }),
    period() {
      return this.periods.find($0 => $0.id === this.document.period_id)
    },
    specializations() {
      return this.period?.pensums?.map($0 => $0.specialization) ?? []
    },
    specialization() {
      return this.specializations?.find($0 => $0.id === this.document.specialization_id)
    },
    semesters() {
      return this.period?.pensums?.find($0 => $0.specialization_id === this.document.specialization_id)
        ?.semesters ?? []
    },
    semester() {
      return this.semesters.find($0 => $0.id === this.document.semester_id)
    },
    courses() {
      return this.semester?.courses ?? []
    }
  },
  methods: {
    ...mapMutations({
      setDocument: 'documents/SET'
    }),
    ...mapActions({
      fetchDocument: 'documents/show',
      update: 'documents/update',
      removeError: 'removeError',
      fetchCourses: 'courses/index',
      fetchSpecializations: 'specializations/fetchSpecializations',
      fetchPeriods: 'periods/index'
    })
  },
  created() {
    const { id } = this.$route.params
    Promise.all([
      this.fetchDocument({ id, query: {} }),
      this.$repository.periods.index({
        limit: 0,
        with: `pensums.specialization,pensums.semesters.courses,pensums.semesters.sections`
      })
    ])
      .then(([document, { data: periods }]) => {
        this.document = document
        this.periods = periods
      })
      ._loading(() => this.loadings.periods = !this.loadings.periods)
  }
}
</script>
